import {useQuery, useLazyQuery, useMutation} from "@apollo/client";
import {
  faEye,
  faPlus,
  faLock,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Checkbox, Group, MantineSize, Menu} from "@mantine/core";
import {noop} from "lodash";
import Link from "next/link";
import {ReactNode} from "react";

import {useAuth} from "./contexts/AuthContext";
import Loader from "./Loader";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import type {SearchResultType, Watch} from "../graphql/types";
import {
  addSearchResultToWatchIds,
  removeSearchResultFromWatchIds,
} from "../utils";

export interface Props {
  target: Partial<SearchResultType>;
  size?: MantineSize;
  variant?: string;
  onWatchUpdated?(watch: Watch): void;
}

export default function WatchButton({
  target,
  size,
  variant = "default",
  onWatchUpdated = noop,
}: Props) {
  const {authentication} = useAuth();
  const {
    loading: watchesLoading,
    error: watchesError,
    data: {watches: {watchList: watches = []} = {}} = {},
  } = useQuery(queries.GET_WATCH_LIST, {
    variables: {
      status: "ACTIVE",
    },
  });
  const [doGetWatch, _getWatchResult] = useLazyQuery(queries.GET_WATCH);
  const [updateWatch, _updateWatchResult] = useMutation(mutations.UPDATE_WATCH);

  // Human-readable name of the target this watch button is for
  const targetType = (target.__typename || "").toLowerCase();

  const getWatch = async (watchId: number): Promise<Watch | null> => {
    const {
      loading,
      error,
      data: {watches: {watch = undefined} = {}} = {},
    } = await doGetWatch({variables: {id: watchId}});
    if (!watch) {
      alert(`Unable to load watch ${watchId}`);
      return null;
    }

    return watch;
  };

  const addTargetToWatch = async (watchId: number) => {
    const watch = await getWatch(watchId);
    if (!watch) {
      return;
    }

    const watchIds = addSearchResultToWatchIds(target, watch);
    await updateWatch({
      variables: {
        watchId,
        ...watchIds,
      },
    });

    onWatchUpdated(watch);
  };

  const removeTargetFromWatch = async (watchId: number) => {
    const watch = await getWatch(watchId);
    if (!watch) {
      return;
    }

    const watchIds = removeSearchResultFromWatchIds(target, watch);
    await updateWatch({
      variables: {
        watchId,
        ...watchIds,
      },
    });

    onWatchUpdated(watch);
  };

  /**
   * Is the curent target being watched by a given watch?
   *
   * @param watch Watch to query
   * @return Whether or not the current target is being watched
   */
  const isTargetWatched = (watch: Watch) => {
    if (!target.id) {
      return false;
    }

    if (target.__typename === "Park") {
      return (watch.parks || []).map(park => park.id).includes(target.id);
    } else if (target.__typename === "Facility") {
      return (watch.facilities || [])
        .map(facility => facility.id)
        .includes(target.id);
    } else if (target.__typename === "Unit") {
      return (watch.units || []).map(unit => unit.id).includes(target.id);
    }

    return false;
  };

  // Build watch button dropdown items
  let watchItems: ReactNode[];
  let anyWatched = false;
  if (watchesLoading) {
    // Currently loading watches
    watchItems = [
      <Menu.Item key="loading">
        <Loader />
      </Menu.Item>,
    ];
  } else if (watchesError) {
    // Error loading watches
    watchItems = [
      <Menu.Item key="error">
        <div>Error loading watches</div>
      </Menu.Item>,
    ];
  } else {
    // Watches loaded
    watchItems = [];
    for (const watch of watches) {
      const watched = isTargetWatched(watch);
      if (watched) {
        anyWatched = true;
      }

      watchItems.push(
        <Menu.Item
          key={watch.id}
          onClick={async () => {
            if (!watched) {
              await addTargetToWatch(watch.id);
            } else {
              await removeTargetFromWatch(watch.id);
            }
          }}>
          <Group
            title={
              watched
                ? `Remove this ${targetType} from watch: ${watch.displayName}`
                : `Add this ${targetType} to watch: ${watch.displayName}`
            }>
            <Checkbox size="xs" checked={watched} readOnly />
            {watch.displayName}
          </Group>
        </Menu.Item>
      );
    }
  }

  const createWatchUrl = (): string => {
    const params: string[] = [];

    if (target.__typename == "Park") {
      params.push(`parkId=${target.id}`);
    } else if (target.__typename == "Facility") {
      params.push(`facilityId=${target.id}`);
    } else if (target.__typename == "Unit") {
      params.push(`unitId=${target.id}`);
    }

    return `/watches/new?${params.join("&")}`;
  };

  // Watch button
  if (!authentication.user) {
    // Not signed in
    return (
      <Link href="/watches" legacyBehavior passHref>
        <Button
          component="a"
          size={size}
          variant={variant}
          title={`Sign in to watch this ${targetType}`}
          rightSection={<FontAwesomeIcon icon={faLock} />}>
          Watch
        </Button>
      </Link>
    );
  } else if (
    watchesLoading ||
    watchesError ||
    (watches && watches.length > 0)
  ) {
    // Multiple active watches: Show dropdown menu on click
    return (
      <Menu
        transitionProps={{transition: "pop"}}
        position="bottom-start"
        withinPortal>
        <Menu.Target>
          <Button
            size={size}
            variant={variant}
            disabled={!authentication.user}
            title={
              anyWatched
                ? `Update watches with this ${targetType}`
                : `Watch this ${targetType}`
            }
            leftSection={
              <FontAwesomeIcon
                beat={anyWatched && !watchesLoading}
                icon={faEye}
              />
            }>
            Watch&hellip;
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {watchItems}
          <Menu.Divider />
          {/*
          <Link href="/watches" target="_blank" legacyBehavior passHref>
            <Menu.Item
              key="new"
              title="Manage all watches"
              leftSection={<FontAwesomeIcon icon={faPencil} />}>
              Manage watches
            </Menu.Item>
          </Link>
          */}
          <Link href={createWatchUrl()} target="_blank" legacyBehavior passHref>
            <Menu.Item
              key="new"
              title={`Create a new watch with this ${targetType}`}
              leftSection={<FontAwesomeIcon icon={faPlus} />}>
              Create watch with this {targetType}...
            </Menu.Item>
          </Link>
        </Menu.Dropdown>
      </Menu>
    );
  } else {
    // No active watches: Show "Create Watch" dialog on click
    return (
      <Link href={createWatchUrl()} target="_blank" legacyBehavior passHref>
        <Button
          component="a"
          size={size}
          variant={variant}
          disabled={!authentication.user}
          title={`Watch this ${targetType}`}
          leftSection={<FontAwesomeIcon icon={faEye} />}>
          Watch&hellip;
        </Button>
      </Link>
    );
  }
}
