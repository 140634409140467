import {useQuery} from "@apollo/client";
import {
  faEye,
  faPeopleGroup,
  faDog,
  faWheelchair,
  faCar,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Group} from "@mantine/core";
import className from "classnames";
import dayjs from "dayjs";
import {isEmpty} from "lodash";
import Link from "next/link";
import React from "react";

import classes from "./FacilityUnitsTable.module.scss";
import Slot from "./Slot";
import * as constants from "../constants";
import * as queries from "../graphql/queries";
import type {Facility, Unit} from "../graphql/types";

export interface Props {
  facility: Partial<Facility>;
  units?: Partial<Unit>[];
  beginDate?: string | null;
  endDate?: string | null;
}

export default function FacilityUnitsTable({
  facility,
  units,
  beginDate,
  endDate,
}: Props) {
  let lastLoopName: string | undefined | null = null;

  const {data: {watches: {watchList: watches = []} = {}} = {}} = useQuery(
    queries.GET_WATCH_LIST,
    {
      variables: {
        status: "ACTIVE",
      },
    }
  );

  units ||= facility.units;

  if (isEmpty(units)) {
    return null;
  }

  // Build lookup of watched units
  const watchedUnits: Set<number> = new Set();
  for (const watch of watches) {
    for (const unit of watch.units) {
      if (unit.facility?.id === facility.id) {
        watchedUnits.add(unit.id);
      }
    }
  }

  return (
    <div
      className={className("facility-units-table", classes.facilityUnitsTable)}>
      {units?.map(unit => {
        let loopHeader = null;
        if (
          (!lastLoopName && unit.loopName) ||
          (lastLoopName && lastLoopName !== unit.loopName)
        ) {
          lastLoopName = unit.loopName;
          loopHeader = (
            <div key={`loop-${unit.loopName}`} className={classes.unitLoopRow}>
              <h1>{unit.loopName}</h1>
            </div>
          );
        }

        let peopleCapacity;
        if (unit.peopleCapacity) {
          if (unit.peopleCapacity.min && unit.peopleCapacity.max) {
            if (unit.peopleCapacity.min > unit.peopleCapacity.max) {
              peopleCapacity = `${unit.peopleCapacity.min} people max`;
            } else {
              peopleCapacity = `${unit.peopleCapacity.min}-${unit.peopleCapacity.max} people`;
            }
          } else if (unit.peopleCapacity.max) {
            peopleCapacity = `${unit.peopleCapacity.max} people max`;
          }
        }

        let slotsBody;
        if (!isEmpty(unit.slots)) {
          slotsBody = (
            <>
              {unit.slots?.map((slot, i) => (
                <Slot key={i} unit={unit} slot={slot} />
              ))}
            </>
          );
        } else {
          slotsBody = (
            <div className={classes.noSlots}>Schedule is unavailable</div>
          );
        }

        return (
          <React.Fragment key={`unit-${unit.id}`}>
            {loopHeader}

            <div className={classes.unitRow}>
              <div className={classes.unitImage}>
                {unit.primaryMedia && (
                  <img
                    className={classes.unitImage}
                    src={unit.primaryMedia.url || constants.NOT_FOUND_IMAGE_URL}
                    alt="Camp site photo"
                  />
                )}
              </div>
              <div className={classes.unitDetailsAndSlots}>
                <div className={classes.unitDetails}>
                  <Link
                    href={
                      beginDate && endDate
                        ? `/units/${unit.id}?beginDate=${dayjs(
                            beginDate
                          ).format(constants.URL_DATE_FORMAT)}&endDate=${dayjs(
                            endDate
                          ).format(constants.URL_DATE_FORMAT)}`
                        : `/units/${unit.id}`
                    }
                    legacyBehavior
                    passHref>
                    <a className={className("unit-link", classes.unitName)}>
                      {unit.name}
                    </a>
                  </Link>
                  {unit.isAdaAccessible && (
                    <div
                      className={classes.unitFact}
                      title="This camp site is ADA accessible">
                      <Group gap="xs">
                        <FontAwesomeIcon icon={faWheelchair} size="sm" />
                        <span>ADA</span>
                      </Group>
                    </div>
                  )}
                  {unit.petsAllowed && (
                    <div
                      className={classes.unitFact}
                      title="Pets are allowed at this camp site">
                      <Group gap="xs">
                        <FontAwesomeIcon icon={faDog} size="sm" />
                        <span>Pets OK</span>
                      </Group>
                    </div>
                  )}
                  {peopleCapacity && (
                    <div
                      className={classes.unitFact}
                      title="This camp site has a limit on the number of people that can stay">
                      <Group gap="xs">
                        <FontAwesomeIcon icon={faPeopleGroup} size="sm" />
                        <span>{peopleCapacity}</span>
                      </Group>
                    </div>
                  )}
                  {!!unit.maxVehicles && (
                    <div
                      className={classes.unitFact}
                      title="This camp site has a limit on the number of vehicles that can park">
                      <Group gap="xs">
                        <FontAwesomeIcon icon={faCar} size="sm" />
                        <span>{unit.maxVehicles} vehicles max</span>
                      </Group>
                    </div>
                  )}
                  {unit.id && watchedUnits.has(unit.id) && (
                    <div
                      className={classes.unitFact}
                      title="You are watching this camp site">
                      <Group gap="xs">
                        <FontAwesomeIcon icon={faEye} size="sm" beat />
                        <span>Watching</span>
                      </Group>
                    </div>
                  )}
                </div>
                <div className={classes.slots}>{slotsBody}</div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
