import {Loader as MantineLoader} from "@mantine/core";

import classes from "./Loader.module.scss";

export interface Props {
  size?: "sm";
  centered?: boolean;
}

export default function Loader({size = "sm", centered = false}: Props) {
  const icon = <MantineLoader size={size} />;

  if (centered) {
    return <div className={classes.loaderContainer}>{icon}</div>;
  }

  return icon;
}
