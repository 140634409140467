import {track} from "@amplitude/analytics-browser";
import {Button, Center, HoverCard, Space, Table, Tooltip} from "@mantine/core";
import classNames from "classnames";
import dayjs from "dayjs";
import {kebabCase, startCase, toLower} from "lodash";
import Link from "next/link";

import ReserveUnitButton from "./ReserveUnitButton";
import classes from "./Slot.module.scss";
import WatchButton from "./WatchButton";
import * as constants from "../constants";
import type {Unit, Slot} from "../graphql/types";

export interface Props {
  unit: Partial<Unit>;
  slot: Slot;
}

export default function Slot({unit, slot}: Props) {
  const now = dayjs();
  const staleDate = now.subtract(constants.SLOT_STALE_THRESHOLD_DAYS, "days");
  const slotDate = dayjs(slot.slotDate);
  const reservableAt = slot.reservableAt ? dayjs(slot.reservableAt) : null;
  const updatedAt = dayjs(slot.updatedAt);

  const handleClick = () => {
    track("Reserve Unit Button Clicked", {
      unit: {
        id: unit.id,
        name: unit.name,
      },
      slot: {
        id: slot.id,
      },
      source: "slot",
    });
  };

  /*
  const absorbTouchEvent = (event: any) => {
    event.preventDefault && event.preventDefault();
    event.stopPropagation && event.stopPropagation();
    event.cancelBubble = true;
    event.returnValue = false;
    return false;
  };
  */

  const slotColorClass = `slot-status-${kebabCase(
    toLower(slot.status || "unknown")
  )}`;

  const slotAvailable = slot.status === "AVAILABLE";

  return (
    <HoverCard width={250} openDelay={300} shadow="md" withArrow arrowSize={16}>
      <HoverCard.Target>
        <div
          key={slot.id}
          className={classNames(classes.slot, slotColorClass, {
            [classes.stale]: updatedAt.isBefore(staleDate),
            [classes.weekend]: slotDate.day() === 0 || slotDate.day() === 6,
          })}
          /*
          onTouchStart={absorbTouchEvent}
          onTouchEnd={absorbTouchEvent}
          onTouchMove={absorbTouchEvent}
          onTouchCancel={absorbTouchEvent}
          */
        >
          <Link href={unit.bookingUrl || "#"} legacyBehavior passHref>
            <a onClick={handleClick} target="_blank">
              {slotDate.toDate().toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
              })}
            </a>
          </Link>
          <br />
          <Link href={unit.bookingUrl || "#"} legacyBehavior passHref>
            <a onClick={handleClick} target="_blank">
              {slotDate.toDate().toLocaleDateString("en-US", {
                weekday: "short",
              })}
            </a>
          </Link>
        </div>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Center>
          <Button.Group>
            <ReserveUnitButton
              unit={unit}
              source="slot"
              size="xs"
              variant={slotAvailable ? "primary" : "default"}
            />
            <WatchButton
              target={unit}
              size="xs"
              variant={slotAvailable ? "default" : "primary"}
            />
          </Button.Group>
        </Center>
        <Space h="md" />
        <Table>
          <Table.Tbody>
            {unit.loopName && (
              <Table.Tr>
                <Table.Th>Loop</Table.Th>
                <Table.Td>{unit.loopName}</Table.Td>
              </Table.Tr>
            )}
            <Table.Tr>
              <Table.Th>Site</Table.Th>
              <Table.Td>{unit.name}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Date</Table.Th>
              <Table.Td>{slotDate.format("L")}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Status</Table.Th>
              <Table.Td>
                <div className={classNames(classes.statusText, slotColorClass)}>
                  {startCase(toLower(slot.status || "unknown"))}
                </div>
              </Table.Td>
            </Table.Tr>
            {reservableAt && (
              <Table.Tr>
                <Table.Th>Reservable</Table.Th>
                <Table.Td>
                  <Tooltip withArrow label={reservableAt.format("llll")}>
                    <span>{reservableAt.fromNow()}</span>
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            )}
            <Table.Tr>
              <Table.Th>Checked</Table.Th>
              <Table.Td>
                <Tooltip withArrow label={updatedAt.format("llll")}>
                  <span>{updatedAt.fromNow()}</span>
                </Tooltip>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
