import {track} from "@amplitude/analytics-browser";
import {faTicket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, MantineSize} from "@mantine/core";
import Link from "next/link";

import type {Unit} from "../graphql/types";

export interface Props {
  unit: Partial<Unit>;
  title?: string | null;
  source?: string;
  variant?: string;
  size?: MantineSize;
}

export default function ReserveUnitButton({
  unit,
  title,
  source,
  variant = "primary",
  size = "lg",
}: Props) {
  function handleClick() {
    track("Reserve Unit Button Clicked", {
      unit: {
        id: unit.id,
        name: unit.name,
      },
      source,
    });
  }

  if (title === undefined) {
    title = "Reserve";
  }

  return (
    <Button
      variant={variant}
      className="reserve-unit-button"
      size={size}
      disabled={!unit.bookingUrl}
      component={Link}
      href={unit.bookingUrl || "#"}
      target="_blank"
      onClick={handleClick}
      leftSection={<FontAwesomeIcon icon={faTicket} />}>
      {title}
    </Button>
  );
}
